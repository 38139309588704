<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BTable,
    VBTooltip
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { numberFormat } from '@/util/helper';

export default {
    name: 'PaynetServices',
    components: {
        TableActionBtns,
        PageTitle,
        BRow,
        BTable,
        BButton,
        BCol,
        BModal,
        BFormCheckbox,
        BFormGroup,
        BFormInput
    },
    mixins: [CRUDPageMixin],
    directives: {
        'b-tooltip': VBTooltip
    },
    data: () => ({
        services: [],
        dataForModal: {
            title: '',
            title_short: '',
            min_amount: '',
            max_amount: '',
            cashback: '',
            paynet_service_id: '',
            fields: [
                {
                    title: {
                        uz: '',
                        ru: ''
                    },
                    key: '',
                    required: false,
                    read_only: false,
                    field_type: true
                }
            ]
        }
    }),
    computed: {
        tableHeader() {
            return [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title_short',
                    label: this.$t('titles.title')
                },
                {
                    key: 'title',
                    label: this.$t('titles.title')
                },
                {
                    key: 'min_amount',
                    label: 'Min Amount'
                },
                {
                    key: 'max_amount',
                    label: 'Max Amount'
                },
                {
                    key: 'cashback',
                    label: 'Cashback'
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        }
    },
    methods: {
        async getServices() {
            const { data } = await api.paynetServices.getServicesApi();
            this.services = data;
        },

        async deleteService(id, index) {
            try {
                await api.paynetServices.deleteServiceApi(id);
                this.services.splice(index, 1);
                this.$toast.success(this.$t('success.deleted'));
            } catch (e) {
                this.$toast.error(e.message);
            }
        },

        async create() {
            try {
                const { data } = await api.paynetServices.createServiceApi(this.dataForModal);
                this.services.push(data);
            } catch (e) {
                this.$toast.error(e.message);
            }
        },

        async update() {
            try {
                await api.paynetServices.updateServiceApi(this.updatingItem.id, this.dataForModal);
            } catch (e) {
                this.$toast.error(e.message);
            }
        },

        addField() {
            const answers = this.dataForModal.fields;
            const lastAnswer = answers[answers.length - 1];
            let canAddAnswer = true;
            if (lastAnswer)
                Object.keys(lastAnswer).forEach((key) => {
                    if (key === 'title') {
                        Object.keys(lastAnswer[key]).forEach((childKey) => {
                            if (!lastAnswer[key][childKey]) return (canAddAnswer = false);
                        });
                    }
                });
            if (!canAddAnswer) return;
            this.dataForModal.fields.push({
                title: {
                    uz: '',
                    ru: ''
                },
                key: '',
                required: false,
                read_only: false,
                field_type: false
            });
        },

        removeField(index) {
            if (this.dataForModal.fields.length < 2) return;
            this.dataForModal.fields.splice(index, 1);
        },

        openCreateModal() {
            if (!this.dataForModal.fields.length) {
                this.dataForModal.fields.push({
                    title: {
                        uz: '',
                        ru: ''
                    },
                    key: '',
                    required: false,
                    read_only: false,
                    field_type: false
                });
            }
            this.isOpenModal = true;
        },

        formatNumber(num) {
            return numberFormat(num);
        }
    },
    mounted() {
        this.getServices();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> Services </template>
            <template #button v-if="hasAccess('gifts', 'create')">
                <b-button variant="success" class="ml-auto" @click="openCreateModal">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <b-row>
            <!--  TABLE  -->
            <b-col cols="12">
                <div class="card">
                    <b-table
                        :fields="tableHeader"
                        :items="services"
                        :responsive="true"
                        striped
                        borderless
                        outlined
                    >
                        <template #cell(index)="{ index }">
                            {{ index + 1 }}
                        </template>
                        <template #cell(max_amount)="{ item }">
                            {{ formatNumber(item.max_amount) }}
                        </template>
                        <template #cell(actions)="{ item, index }">
                            <table-action-btns
                                :item="item"
                                :index="index"
                                :delete-handler="deleteService"
                                :delete-access="{ section: 'gifts', permission: 'delete' }"
                                :update-access="{ section: 'gifts', permission: 'update' }"
                                @openModal="openModalToUpdate"
                            />
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>

        <b-modal
            v-model="isOpenModal"
            id="createUpdateModal"
            cancel-variant="danger"
            size="xl"
            centered
            :hide-footer="false"
            :no-close-on-backdrop="false"
            :title="$t('add.organization')"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    TITLE SHORT    -->
                    <b-col cols="4">
                        <label>
                            {{ $t('titles.title') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.title')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title_short"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('enter.title')"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TITLE    -->
                    <b-col cols="8">
                        <label>
                            {{ $t('titles.title') }}
                        </label>
                        <ValidationProvider
                            :name="$t('titles.title')"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.title"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('enter.title')"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    MIN AMOUNT    -->
                    <b-col cols="3">
                        <label> Min amount </label>
                        <ValidationProvider
                            :name="`${$t('titles.offer')} ID`"
                            rules="required|numeric"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.min_amount"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="`${$t('enter.offer')} ID`"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    MAX AMOUNT    -->
                    <b-col cols="3">
                        <label> Max amount </label>
                        <ValidationProvider
                            :name="`${$t('titles.offer')} ID`"
                            rules="required|numeric"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.max_amount"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="`${$t('enter.offer')} ID`"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    CASHBACK    -->
                    <b-col cols="3">
                        <label> Cashback </label>
                        <ValidationProvider
                            :name="`${$t('titles.offer')} ID`"
                            rules="required|numeric"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.cashback"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="`${$t('enter.offer')} ID`"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    PAYNET SERVICE ID    -->
                    <b-col cols="3">
                        <label> Paynet service ID </label>
                        <ValidationProvider
                            :name="`${$t('titles.offer')} ID`"
                            rules="required|numeric"
                            v-slot="{ errors }"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.paynet_service_id"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="`${$t('enter.offer')} ID`"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <template v-for="(field, index) in dataForModal.fields">
                        <b-col cols="12">
                            <hr />
                        </b-col>

                        <b-col cols="12">
                            <h2>Field {{ index + 1 }}</h2>
                        </b-col>

                        <!--    FIELD TITLE UZ    -->
                        <b-col cols="4">
                            <label> Field Title (UZ) </label>
                            <ValidationProvider
                                :name="$t('titles.title')"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="field.title.uz"
                                        size="lg"
                                        :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('enter.title')"
                                    />
                                </b-form-group>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>

                        <!--    FIELD TITLE RU    -->
                        <b-col cols="4">
                            <label> Field Title (RU) </label>
                            <ValidationProvider
                                :name="$t('titles.title')"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="field.title.ru"
                                        size="lg"
                                        :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('enter.title')"
                                    />
                                </b-form-group>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>

                        <!--    KEY    -->
                        <b-col cols="4">
                            <label> Key </label>
                            <ValidationProvider
                                :name="$t('titles.title')"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="field.key"
                                        size="lg"
                                        :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('enter.title')"
                                    />
                                </b-form-group>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>

                        <!--    REQUIRED    -->
                        <b-col cols="4">
                            <div class="d-flex align-items-center h-100">
                                <b-form-checkbox v-model="field.required" class="mr-auto">
                                    Required
                                </b-form-checkbox>
                            </div>
                        </b-col>

                        <!--    READ ONLY    -->
                        <b-col cols="4">
                            <div class="d-flex align-items-center h-100">
                                <b-form-checkbox v-model="field.read_only" class="mr-auto">
                                    Read only
                                </b-form-checkbox>
                            </div>
                        </b-col>

                        <b-col cols="4">
                            <div class="d-flex justify-content-end">
                                <b-button
                                    class="btn-icon"
                                    style="height: 45px"
                                    variant="danger"
                                    @click="removeField(index)"
                                >
                                    <feather-icon icon="Trash2Icon" />
                                </b-button>
                            </div>
                        </b-col>
                    </template>

                    <b-col cols="12">
                        <div class="d-flex mt-2">
                            <b-button variant="primary" class="ml-auto" @click="addField">
                                {{ $t('add.answer') }}
                                <feather-icon icon="PlusIcon" />
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button @click="modalSubmit" variant="success">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped></style>
