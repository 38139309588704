var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Services ")]},proxy:true},(_vm.hasAccess('gifts', 'create'))?{key:"button",fn:function(){return [_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"success"},on:{"click":_vm.openCreateModal}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card"},[_c('b-table',{attrs:{"fields":_vm.tableHeader,"items":_vm.services,"responsive":true,"striped":"","borderless":"","outlined":""},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(max_amount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.max_amount))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('table-action-btns',{attrs:{"item":item,"index":index,"delete-handler":_vm.deleteService,"delete-access":{ section: 'gifts', permission: 'delete' },"update-access":{ section: 'gifts', permission: 'update' }},on:{"openModal":_vm.openModalToUpdate}})]}}])})],1)])],1),_c('b-modal',{attrs:{"id":"createUpdateModal","cancel-variant":"danger","size":"xl","centered":"","hide-footer":false,"no-close-on-backdrop":false,"title":_vm.$t('add.organization')},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(!_vm.updatingItem)?_c('b-form-checkbox',{staticClass:"mr-auto",model:{value:(_vm.createOneMore),callback:function ($$v) {_vm.createOneMore=$$v},expression:"createOneMore"}},[_vm._v(" "+_vm._s(_vm.$t('titles.add_more'))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.modalSubmit}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter.title')},model:{value:(_vm.dataForModal.title_short),callback:function ($$v) {_vm.$set(_vm.dataForModal, "title_short", $$v)},expression:"dataForModal.title_short"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter.title')},model:{value:(_vm.dataForModal.title),callback:function ($$v) {_vm.$set(_vm.dataForModal, "title", $$v)},expression:"dataForModal.title"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('label',[_vm._v(" Min amount ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.offer')) + " ID"),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":((_vm.$t('enter.offer')) + " ID")},model:{value:(_vm.dataForModal.min_amount),callback:function ($$v) {_vm.$set(_vm.dataForModal, "min_amount", $$v)},expression:"dataForModal.min_amount"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('label',[_vm._v(" Max amount ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.offer')) + " ID"),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":((_vm.$t('enter.offer')) + " ID")},model:{value:(_vm.dataForModal.max_amount),callback:function ($$v) {_vm.$set(_vm.dataForModal, "max_amount", $$v)},expression:"dataForModal.max_amount"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('label',[_vm._v(" Cashback ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.offer')) + " ID"),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":((_vm.$t('enter.offer')) + " ID")},model:{value:(_vm.dataForModal.cashback),callback:function ($$v) {_vm.$set(_vm.dataForModal, "cashback", $$v)},expression:"dataForModal.cashback"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('label',[_vm._v(" Paynet service ID ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.offer')) + " ID"),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":((_vm.$t('enter.offer')) + " ID")},model:{value:(_vm.dataForModal.paynet_service_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "paynet_service_id", $$v)},expression:"dataForModal.paynet_service_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_vm._l((_vm.dataForModal.fields),function(field,index){return [_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Field "+_vm._s(index + 1))])]),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" Field Title (UZ) ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter.title')},model:{value:(field.title.uz),callback:function ($$v) {_vm.$set(field.title, "uz", $$v)},expression:"field.title.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" Field Title (RU) ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter.title')},model:{value:(field.title.ru),callback:function ($$v) {_vm.$set(field.title, "ru", $$v)},expression:"field.title.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" Key ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"lg","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter.title')},model:{value:(field.key),callback:function ($$v) {_vm.$set(field, "key", $$v)},expression:"field.key"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('b-form-checkbox',{staticClass:"mr-auto",model:{value:(field.required),callback:function ($$v) {_vm.$set(field, "required", $$v)},expression:"field.required"}},[_vm._v(" Required ")])],1)]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('b-form-checkbox',{staticClass:"mr-auto",model:{value:(field.read_only),callback:function ($$v) {_vm.$set(field, "read_only", $$v)},expression:"field.read_only"}},[_vm._v(" Read only ")])],1)]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"btn-icon",staticStyle:{"height":"45px"},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeField(index)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1)])]}),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"primary"},on:{"click":_vm.addField}},[_vm._v(" "+_vm._s(_vm.$t('add.answer'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }